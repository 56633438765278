import { apiClient } from "@/services/HttpService";

// 获取任务列表
export function fetchTaskList(params) {
  return apiClient.get(
    `/flowTask/pageList?taskStatus=${params.taskStatus}&flowStatus=${params.flowStatus}&queryStartTime=${params.queryStartTime}&queryEndTime=${params.queryEndTime}&taskType=${params.taskType}&taskTitle=${params.taskTitle}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 获取流程类型列表
export function fetchProcessTypeList() {
  return apiClient.get(`/flowTask/type`);
}
